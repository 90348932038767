import React from "react"

function Table() {
    return (
        <div>
            <h1>Monthly <strong className="purple">Growth</strong> Packages </h1>

            <table className="table">
            <thead>
            <tr>
                <th><strong className="purple">Starter</strong></th>
                <th><strong className="purple">Brand Builder</strong></th>
                <th><strong className="purple">Brand Growth Accelerator</strong></th>
            </tr>
            </thead>
            <tbody>
            <tr>
                <td>Automatic Customer Re-Activation</td>
                <td>Everything in <strong className="purple">Starter</strong></td>
                <td>Everything in <strong className="purple">Brand Builder</strong></td>
            </tr>
            <tr>
                <td>Ad Campaign Coordination</td>
                <td>Professional Photography</td>
                <td>Reputation & Reviews Management</td>
            </tr>
            <tr>
                <td>Active SEO Optimization</td>
                <td>Premium Video Branding</td>
                <td>Invoicing, Contracts, Coupons, etc.</td>
            </tr>
            <tr>
                <td><strong className="purple">FREE </strong>Custom Website</td>
                <td>Daily Social Media Posts</td>
                <td><strong className="purple">Lamar™ </strong> A.I. Appointment Setter</td>
            </tr>
            <tr>
                <td></td>
                <td>Monthly Media Day</td>
                <td>All-In-One Conversations Center</td>
            </tr>
            <tr>
                <td></td>
                <td></td>
                <td>Full <strong className="purple">CRM </strong>Dashboard Access</td>
            </tr>
            <tr>
                <td></td>
                <td></td>
                <td>Social Media Management</td>
            </tr>
            <tr>
                <td>$200</td>
                <td>$900</td>
                <td>$1,800</td>
            </tr>
            </tbody>
        </table>    

        </div>
    )
}

export default Table;